import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import About from './pages/About';
import Blog from './pages/Blog';
import Navbar from './components/navbar/Navbar';
import withRoot from './pages/withRoot';
import React from 'react';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import NotFound from './pages/NotFound';
import BlogDetails from './components/blog/BlogDetails';
import CreateBlog from './pages/CreateBlog';
import Products from './pages/Products';
import ActivityDetailView from './pages/Activity/ActivityDetailView';
import Admin from './pages/Admin';
import { AuthProvider, useAuth } from './context/AuthContext';
import { MediaQueryProvider } from './context/MediaQueryProvider';

const PrivateRoute = ({ element, ...rest }) => {
  const { state } = useAuth();
  return state.isAuthenticated ? element : <Navigate to="/" replace />;
};

function App() {
  return (
    <React.Fragment>
      <AuthProvider>
        <MediaQueryProvider>
          <Router>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/blogs" element={<Blog />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/createblog" element={<CreateBlog />} />
              <Route path="*" element={<NotFound />} />
              <Route path="/blogs/:id" element={<BlogDetails />} />
              <Route path="/products" element={<Products tabValue="2" />} />
              <Route path="/products/:id" element={<ActivityDetailView />} />
              <Route
                path="/admin"
                element={
                  <PrivateRoute>
                    <Admin />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Router>
        </MediaQueryProvider>
      </AuthProvider>
    </React.Fragment>
  );
}

export default withRoot(App);
