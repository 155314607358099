// import { loadUserFromLocalStorage } from '../redux/userAuth/userAuthSlice';
import apiClient from './apiClient';

export const getComments = async (blogId) => {
  try {
    const response = await apiClient.get(`/api/blogs/${blogId}/comment`);
    console.log('comments data', response.data.data);
    return response.data;
  } catch (error) {
    console.error('Error fetching comments:', error);
  }
};
export const handleCommentSubmit = async (data, blogId) => {
  const userData = loadUserFromLocalStorage();
  try {
    data.userName = userData.firstName;
    // console.log(data, blogId, userData);
    const response = await apiClient.post(`/api/blogs/${blogId}/comment`, data);
    console.log('API response:', response.data);
    alert('comment was added successfully');
  } catch (error) {
    console.error('Failed to add a comment', error);
  }
};

export const handleDelete = async (commentId, blogId) => {
  try {
    const response = await apiClient.delete(`/api/blogs/${blogId}/comment`, {
      data: { commentId: commentId },
    });
    console.log(response.data);
    alert('comment deleted successfully');
  } catch (error) {
    console.error('Error deleting comment:', error);
  }
};
export const updateComment = async (commentId, blogId, updatedComment) => {
  try {
    const response = await apiClient.put(`/api/blogs/${blogId}/commentupdate`, {
      commentId: commentId,
      comment: updatedComment,
    });
    console.log(response.data);
    alert('Comment updated successfully');
  } catch (error) {
    console.error('error while updating comment', error);
  }
};
