import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { useParams } from 'react-router-dom';
import kidsPlayingImage from '../../assets/home/kids_playing.jpg';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { Avatar } from '@mui/material';
import {
  getComments,
  handleCommentSubmit,
  handleDelete,
  updateComment,
} from '../../api/commentService';
import { TextField, Button } from '@mui/material';
import { DeleteOutline, AddCircle } from '@mui/icons-material';
import { Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Edit } from '@mui/icons-material';
// import { loadUserFromLocalStorage } from '../../redux/userAuth/userAuthSlice';
import { formatCounts } from '../blog/BlogListView';
import { fetchBlogDetails } from '../../api/blogService';

const BlogDetails = () => {
  let cookiejwt = Cookies.get('token');
  console.log('jwt', cookiejwt);
  // const User = loadUserFromLocalStorage();
  const [comments, setComments] = useState(null);
  const [blog, setBlog] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [addComment, setAddComment] = useState(false);
  const [editButton, setEditButton] = useState(false);
  const [editingCommentId, setEditingCommentId] = useState('');
  const [editedComment, setEditedComment] = useState('');
  const { id } = useParams();

  useEffect(() => {
    fetchBlogById();
    getBlogComments();
  }, [id]);

  const getBlogComments = async () => {
    try {
      const data = await getComments(id);
      setComments(data.data);
      console.log('blog comments', data.data);
    } catch (error) {
      console.error('Error fetching blog comments:', error);
    }
  };

  const addNewComment = async () => {
    let data = {
      comment: newComment,
    };
    const response = await handleCommentSubmit(data, id);
    setAddComment(false);
    fetchBlogById();
    getBlogComments();
    setNewComment('');
  };
  const deleteComment = async (commentId) => {
    const response = await handleDelete(commentId, id);
    fetchBlogById();
    getBlogComments();
  };

  const updatingComment = async (commentId) => {
    const response = await updateComment(commentId, id, editedComment);
    getBlogComments();
  };

  // Using the imported function
  const fetchBlogById = async () => {
    try {
      const data = await fetchBlogDetails(id);
      setBlog(data.data);
      await incrementBlogViewCount(id);
      // await incrementBlogLikeCount(id);
    } catch (error) {
      console.error('Error fetching blog data:', error);
    }
  };

  return (
    <div>
      {blog && (
        <>
          <div>
            <h2>{blog.title}</h2>
            <img
              height={'250'}
              width={'500'}
              src={
                blog.imageURL && blog.imageURL.length > 0
                  ? blog.imageURL[0]
                  : kidsPlayingImage
              }
              alt={blog.title}
            />
            <h3>{blog.author}</h3>
            <p>Views: {formatCounts(blog.viewCount)}</p>
            <p>Likes: {formatCounts(blog.likeCount)}</p>

            <p>{blog.summary}</p>
            <p>{blog.description}</p>
            <p>{blog.section}</p>
            <p>{blog.reference}</p>
            <p>{blog.tags}</p>
            {
              <div>
                <Typography>{blog.commentIds.length}Comments</Typography>
                {addComment ? (
                  ''
                ) : (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setAddComment(true);
                    }}
                  >
                    Add Comment
                  </Button>
                )}
                {addComment ? (
                  User && cookiejwt != undefined ? (
                    <div>
                      <Avatar src="Profile Icon" />
                      <TextField
                        id="new-comment"
                        label="Add a comment"
                        variant="standard"
                        value={newComment}
                        onChange={(e) => setNewComment(e.target.value)}
                        sx={{
                          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                          '& .MuiInput-root': {
                            border: 'none',
                          },
                          '& .MuiInputLabel-root': {
                            position: 'relative',
                            color: 'rgba(0, 0, 0, 0.42)',
                          },
                        }}
                      />
                      <Button onClick={addNewComment}>Comment</Button>
                      <Button
                        onClick={() => {
                          setAddComment(false), setNewComment('');
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Navigate to="/SignIn" />
                  )
                ) : (
                  ''
                )}
              </div>
            }
            {blog.commentIds && blog.commentIds.length > 0 ? (
              <div>
                {comments
                  ? comments.map((comment) => (
                      <>
                        <Box marginLeft={1}>
                          {User != null && User.userId == comment.userId ? (
                            <IconButton
                              sx={{ float: 'right' }}
                              onClick={() => deleteComment(comment._id)}
                            >
                              <DeleteOutline />
                            </IconButton>
                          ) : (
                            ''
                          )}
                          {User != null &&
                          User.userId == comment.userId &&
                          editButton == false ? (
                            <IconButton
                              sx={{ float: 'right' }}
                              onClick={() => {
                                setEditButton(true);
                                setEditingCommentId(comment._id);
                              }}
                            >
                              <Edit />
                            </IconButton>
                          ) : (
                            ''
                          )}
                          {/* updating comment */}
                          {editButton && editingCommentId == comment._id ? (
                            <div>
                              <TextField
                                label="Edit your comment"
                                variant="standard"
                                value={editedComment}
                                onChange={(e) =>
                                  setEditedComment(e.target.value)
                                }
                                sx={{
                                  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
                                  '& .MuiInput-root': {
                                    border: 'none',
                                  },
                                  '& .MuiInputLabel-root': {
                                    position: 'relative',
                                    color: 'rgba(0, 0, 0, 0.42)',
                                  },
                                }}
                              />
                              <Button
                                onClick={() => updatingComment(comment._id)}
                              >
                                Update
                              </Button>
                              <Button
                                onClick={() => {
                                  setEditButton(false), setEditedComment('');
                                }}
                              >
                                Cancel
                              </Button>
                            </div>
                          ) : (
                            ''
                          )}
                          <Avatar src="Profile Icon" />
                          <Typography variant="body">
                            {comment.userName}
                          </Typography>
                          <Typography
                            variant="h6"
                            sx={{ wordWrap: 'break-word' }}
                          >
                            {comment.comment}
                          </Typography>
                        </Box>
                        <hr />
                      </>
                    ))
                  : ''}
              </div>
            ) : (
              ''
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default BlogDetails;
