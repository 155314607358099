import apiClient from './apiClient';
import ENDPOINTS from './endpoints';

export const RegisterUser = async (data) => {
  try {
    // Make API call using Axios
    console.log(data);
    // const response = await axios.post('/api/signUp', values);
    const response = await apiClient.post(ENDPOINTS.REGISTER, data);
    // console.log('Register API response:', response.data);
    // alert('User Registered successfully!');
    return response.data;
  } catch (error) {
    console.error('Error while user is registering', error);
    throw error;
  }
};

export const LoginUser = async (data) => {
  try {
    const response = await apiClient.post(ENDPOINTS.LOGIN, data);
    console.log('Login API response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error while user is logging', error);
    throw error;
  }
};
